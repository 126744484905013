import { createApp } from 'vue';
// 1. 引入你需要的组件
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router/router.js'

import App from './App.vue'
const app = createApp(App)
app.use( Vant)
// 注入router
app.use(router)
app.mount('#app')
